import {AxiosError} from 'axios'
import {useQuery} from 'react-query'

import {trackEvent} from '../../../analytics'
import {queries} from '../../queries'
import {useErrorHandling} from '../useErrorHandling'

export const useGetSlots = () => {
  const {handleNetworkError, handleUnauthorized, handleApiUnavailable, handleNoPermissions} =
    useErrorHandling()
  return useQuery('slots', queries.slots, {
    onError: (err: AxiosError) => {
      const isNetworkError = err.message === 'Network Error'
      const status = err.response?.status

      const trackEventData = {
        type: 'getSlots'
      }

      const frontendErrorTrackEvent = void trackEvent(
        'hubOrderIntakeSlotFrontendError',
        trackEventData
      )

      if (isNetworkError) handleNetworkError('slots')
      if (status === 401) handleUnauthorized()
      if (status === 403) handleNoPermissions(frontendErrorTrackEvent)
      if (status === 503) handleApiUnavailable()
    }
  })
}
